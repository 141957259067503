import axios from '@/libs/axios';
import { ref } from 'vue';
import { handleApiError } from '@/services/error';

export const useHomePage = () => {
  const aicastats = ref({});

  // Individual loading states for each API call
  const loadingAicastats = ref(false);

  const fetchAicastats = async () => {
    loadingAicastats.value = true;
    try {
      const { data } = await axios.get(`cefdata/aicastats`);
      aicastats.value = data;
    } catch (error) {
      handleApiError(error, 'Error in getting Fund Data');
    } finally {
      loadingAicastats.value = false;
    }
  };

  return {
    fetchAicastats,
    loadingAicastats,
    aicastats,
  };
};
