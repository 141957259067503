import FundProfile from '@/views/fund-profile/FundProfile.vue';
import HomePage from '@/views/home-page/HomePage.vue';

export const viewsRoutes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
  },
  {
    path: '/:ticker',
    name: 'FundProfile',
    component: FundProfile,
  },
];
