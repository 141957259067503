<template>
  <div>
    <LineChart
      :tabs="tabs"
      yAxisFormat="money"
      @tab-changed="onTabChange"
      :key="chartKey"
      :activeTab="currentTab"
      :loading="isLoading"
    />
  </div>
</template>

<script setup>
import LineChart from '@/components/charts/LineChart.vue';
import { formatDateToUSA } from '@/services/utils';
import { useFundProfile } from '../../composable/useFundProfile';
import { ref, watch } from 'vue';

const props = defineProps({
  ticker: {
    type: String,
    required: true,
  },
  growthData: {
    type: Object,
    default: () => ({}),
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const { fetchGrowth10k, loadingGrowth10k, growth10k } = useFundProfile();

const currentTab = ref(0);
const chartKey = ref(Math.random());
const tabs = ref([]);
const isLoading = ref(true);

const getTabsData = (dataSource) => {
  const growthData = dataSource;
  return [
    {
      name: '1 Mo',
      slug: 'mo1',
      title: 'Growth of $10,000',
      labels: growthData.one_month?.date?.map((v) => formatDateToUSA(v)) || [],
      yAxisFormat: 'money',
      datasets: [
        {
          label: 'Price',
          data: growthData.one_month?.price?.map((x) => x) || [],
        },
        {
          label: 'NAV',
          data: growthData.one_month?.nav?.map((x) => x) || [],
        },
        {
          label: 'Peer Price',
          data: growthData.one_month?.price_peer?.map((x) => x) || [],
        },
        {
          label: 'Peer NAV',
          data: growthData.one_month?.nav_peer?.map((x) => x) || [],
        },
      ],
    },
    {
      name: '3 Mo',
      slug: 'mo3',
      title: 'Growth of $10,000',
      labels:
        growthData.three_month?.date?.map((v) => formatDateToUSA(v)) || [],
      yAxisFormat: 'money',
      datasets: [
        {
          label: 'Price',
          data: growthData.three_month?.price?.map((x) => x) || [],
        },
        {
          label: 'NAV',
          data: growthData.three_month?.nav?.map((x) => x) || [],
        },
        {
          label: 'Peer Price',
          data: growthData.three_month?.price_peer?.map((x) => x) || [],
        },
        {
          label: 'Peer NAV',
          data: growthData.three_month?.nav_peer?.map((x) => x) || [],
        },
      ],
    },
    {
      name: 'YTD',
      slug: 'ytd',
      title: 'Growth of $10,000',
      labels: growthData.ytd?.date?.map((v) => formatDateToUSA(v)) || [],
      yAxisFormat: 'money',
      datasets: [
        {
          label: 'Price',
          data: growthData.ytd?.price?.map((x) => x) || [],
        },
        { label: 'NAV', data: growthData.ytd?.nav?.map((x) => x) || [] },
        {
          label: 'Peer Price',
          data: growthData.ytd?.price_peer?.map((x) => x) || [],
        },
        {
          label: 'Peer NAV',
          data: growthData.ytd?.nav_peer?.map((x) => x) || [],
        },
      ],
    },
    {
      name: '1 Yr',
      slug: 'yr1',
      title: 'Growth of $10,000',
      labels: growthData.one_year?.date?.map((v) => formatDateToUSA(v)) || [],
      yAxisFormat: 'money',
      datasets: [
        {
          label: 'Price',
          data: growthData.one_year?.price?.map((x) => x) || [],
        },
        {
          label: 'NAV',
          data: growthData.one_year?.nav?.map((x) => x) || [],
        },
        {
          label: 'Peer Price',
          data: growthData.one_year?.price_peer?.map((x) => x) || [],
        },
        {
          label: 'Peer NAV',
          data: growthData.one_year?.nav_peer?.map((x) => x) || [],
        },
      ],
    },
    {
      name: '3 Yr',
      slug: 'yr3',
      title: 'Growth of $10,000',
      labels: growthData.three_year?.date?.map((v) => formatDateToUSA(v)) || [],
      yAxisFormat: 'money',
      datasets: [
        {
          label: 'Price',
          data: growthData.three_year?.price?.map((x) => x) || [],
        },
        {
          label: 'NAV',
          data: growthData.three_year?.nav?.map((x) => x) || [],
        },
        {
          label: 'Peer Price',
          data: growthData.three_year?.price_peer?.map((x) => x) || [],
        },
        {
          label: 'Peer NAV',
          data: growthData.three_year?.nav_peer?.map((x) => x) || [],
        },
      ],
    },
    {
      name: '5 Yr',
      slug: 'yr5',
      title: 'Growth of $10,000',
      labels: growthData.five_year?.date?.map((v) => formatDateToUSA(v)) || [],
      yAxisFormat: 'money',
      datasets: [
        {
          label: 'Price',
          data: growthData.five_year?.price?.map((x) => x) || [],
        },
        {
          label: 'NAV',
          data: growthData.five_year?.nav?.map((x) => x) || [],
        },
        {
          label: 'Peer Price',
          data: growthData.five_year?.price_peer?.map((x) => x) || [],
        },
        {
          label: 'Peer NAV',
          data: growthData.five_year?.nav_peer?.map((x) => x) || [],
        },
      ],
    },
    {
      name: '10 Yr',
      slug: 'yr10',
      title: 'Growth of $10,000',
      labels: growthData.ten_year?.date?.map((v) => formatDateToUSA(v)) || [],
      yAxisFormat: 'money',
      datasets: [
        {
          label: 'Price',
          data: growthData.ten_year?.price?.map((x) => x) || [],
        },
        {
          label: 'NAV',
          data: growthData.ten_year?.nav?.map((x) => x) || [],
        },
        {
          label: 'Peer Price',
          data: growthData.ten_year?.price_peer?.map((x) => x) || [],
        },
        {
          label: 'Peer NAV',
          data: growthData.ten_year?.nav_peer?.map((x) => x) || [],
        },
      ],
    },
    {
      name: '15 Yr',
      slug: 'yr15',
      title: 'Growth of $10,000',
      labels:
        growthData.fifteen_year?.date?.map((v) => formatDateToUSA(v)) || [],
      yAxisFormat: 'money',
      datasets: [
        {
          label: 'Price',
          data: growthData.fifteen_year?.price?.map((x) => x) || [],
        },
        {
          label: 'NAV',
          data: growthData.fifteen_year?.nav?.map((x) => x) || [],
        },
        {
          label: 'Peer Price',
          data: growthData.fifteen_year?.price_peer?.map((x) => x) || [],
        },
        {
          label: 'Peer NAV',
          data: growthData.fifteen_year?.nav_peer?.map((x) => x) || [],
        },
      ],
    },
  ];
};

// Handle tab change
const onTabChange = async ({ index, tab }) => {
  isLoading.value = true;
  await fetchGrowth10k(props.ticker, tab.slug);
  chartKey.value = Math.random();
  tabs.value = getTabsData(growth10k.value);
  isLoading.value = loadingGrowth10k.value;
  currentTab.value = index;
};

watch(
  () => [props.growthData, props.loading],
  ([newGrowthData, newLoading]) => {
    chartKey.value = Math.random();
    tabs.value = getTabsData(newGrowthData);
    isLoading.value = newLoading;
  },
  { immediate: true }
);
</script>
