<template>
  <LoadingOverlay :loading="loadingDividendHistory">
    <section class="component dark spacing-top none px-4 is-visible">
      <h2>DISTRIBUTION HISTORY (1 YR)</h2>
      <div class="table-responsive">
        <table class="table dark-table">
          <thead>
            <tr>
              <th>Declared</th>
              <th>Payable</th>
              <th>Ex Date</th>
              <th>Distrib</th>
              <th>Income</th>
              <th>Short Gain</th>
              <th>Long Gain</th>
              <th>Roc</th>
              <th class="label">Type</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in first12DividendHistoryData"
              :key="`item_${index}`"
            >
              <td>{{ item.declared_date }}</td>
              <td>{{ item.payable_date }}</td>
              <td>{{ item.ex_date }}</td>
              <td>{{ item.distribution_amount }}</td>
              <td>{{ item.income }}</td>
              <td>{{ item.short_gain }}</td>
              <td>{{ item.long_gain }}</td>
              <td>{{ item.roc }}</td>
              <td class="label">{{ item.type }}</td>
            </tr>
          </tbody>
          <caption>
            <span @click="showPopupHandler" style="cursor: pointer">
              Show Full History
            </span>
          </caption>
        </table>
      </div>
      <!-- /.table-responsive -->
    </section>
  </LoadingOverlay>

  <div
    id="modal-popup-2"
    :class="['modal', { show: showPopup }]"
    tabindex="-1"
    aria-modal="true"
    role="dialog"
    :style="{ display: showPopup ? 'block' : 'none' }"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <section class="component dark spacing-top none px-4 is-visible">
            <div class="d-flex justify-content-between">
              <h3>{{ ticker }} DISTRIBUTION HISTORY</h3>
              <div>
                <button
                  type="button"
                  class="btn-close-modal"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  @click="hidePopupHandler"
                >
                  <i class="fa-sharp fa-solid fa-xmark"></i>
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <table id="datatable-5" class="table dark-table" ref="modalTable">
                <thead>
                  <tr class="arrows">
                    <th>Declared</th>
                    <th>Payable</th>
                    <th>Ex Date</th>
                    <th>Distrib</th>
                    <th>Income</th>
                    <th>Short Gain</th>
                    <th>Long Gain</th>
                    <th>Roc</th>
                    <th class="label">Type</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in dividendHistoryData"
                    :key="`item_${index}`"
                  >
                    <td>{{ item.declared_date }}</td>
                    <td>{{ item.payable_date }}</td>
                    <td>{{ item.ex_date }}</td>
                    <td>{{ item.distribution_amount }}</td>
                    <td>{{ item.income }}</td>
                    <td>{{ item.short_gain }}</td>
                    <td>{{ item.long_gain }}</td>
                    <td>{{ item.roc }}</td>
                    <td class="label">{{ item.type }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- /.table-responsive -->
          </section>
        </div>
        <!-- /.modal-body -->
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, computed, nextTick } from 'vue';
import DataTable from 'datatables.net';
import { formatDateToUSA, formatNumber } from '@/services/utils';
import LoadingOverlay from '@/components/ui/LoadingOverlay.vue';
import { useFundProfile } from '../../composable/useFundProfile';

// Define props
const props = defineProps({
  ticker: {
    type: String,
    required: true,
  },
});

const showPopup = ref(false);
const dataTable = ref(null);
const modalTable = ref(null);
let dtInstance = null;
let modalTableInstance = null;

const { fetchDividendHistory, loadingDividendHistory, dividendHistory } =
  useFundProfile();

const showPopupHandler = async () => {
  showPopup.value = true;
  document.body.classList.add('modal-open');

  await nextTick(); // Ensure modal table is fully rendered
  if (modalTable.value) {
    if (modalTableInstance) modalTableInstance.destroy();
    modalTableInstance = new DataTable(modalTable.value, {
      searching: false,
      paging: false,
      ordering: true,
      autoWidth: false,
    });
  }
};

const hidePopupHandler = () => {
  showPopup.value = false;
  document.body.classList.remove('modal-open');
};

// Process and format dividend history data
const dividendHistoryData = computed(() =>
  dividendHistory.value
    .filter((x) => x.declared_date && Object.keys(x.declared_date).length > 0)
    .map((x) => {
      return {
        declared_date: formatDateToUSA(x.declared_date),
        payable_date: formatDateToUSA(x.payable_date),
        ex_date: formatDateToUSA(x.ex_date),
        distribution_amount: `$${formatNumber(x.distribution_amount, 4)}`,
        income: `$${formatNumber(x.income, 4)}`,
        short_gain: `$${formatNumber(x.short_gain, 4)}`,
        long_gain: `$${formatNumber(x.long_gain, 4)}`,
        roc: `$${formatNumber(x.roc, 4)}`,
        type: x.get_dividend_type_display,
      };
    })
);

const first12DividendHistoryData = computed(
  () => dividendHistoryData.value.slice(0, 12) || []
);

const initDataTable = async () => {
  await nextTick(); // Ensure table is rendered
  if (dtInstance) {
    dtInstance.destroy(); // Destroy previous instance
  }

  if (dataTable.value) {
    dtInstance = new DataTable(dataTable.value, {
      searching: false,
      paging: false,
      ordering: true,
      autoWidth: false,
    });
  }
};

onBeforeMount(async () => {
  await fetchDividendHistory(props.ticker);
});

onMounted(() => {
  initDataTable();
});
</script>
