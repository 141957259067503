<template>
  <div class="table-responsive">
    <table class="table sidebar">
      <thead>
        <tr>
          <th colspan="2">Distribution Data</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in yieldAndDistributionData"
          :key="`item_${index}`"
        >
          <th>
            {{ item.label }}
          </th>
          <td>{{ item.value }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { formatNumber, formatDateToUSA } from '@/services/utils';

const props = defineProps({
  shareClassData: {
    type: Object,
    default: () => ({}),
  },
  shareClass: {
    type: Object,
    default: () => ({}),
  },
  fundProfileData: {
    type: Object,
    default: () => ({}),
  },
});

const yieldAndDistributionData = computed(() => {
  const { shareClass, shareClassData, fundProfileData } = props;

  return {
    marketYield: {
      label: 'Market Yield',
      value: `${formatNumber(shareClass.price?.mk_yield || 0, 2)}%`,
    },
    incomeOnlyYield: {
      label: 'Income-only Yield',
      value: `${formatNumber(shareClass.price?.inc_yield || 0, 2)}%`,
    },
    navYield: {
      label: 'NAV Yield',
      value: `${formatNumber(shareClass.price?.nav_yield || 0, 2)}%`,
    },
    leverageAdjustedNavYield: {
      label: 'Leverage Adjusted NAV Yield',
      value: `${shareClassData.lev_adj_nav_yield?.data_value || 0}%`,
    },
    ...(shareClass.is_fund_type_dbc
      ? {
          yieldOnDebt: {
            label: 'Yield-on Debt',
            value: `${shareClassData.yield_on_debt?.data_value || 0}%`,
          },
        }
      : {}),
    distributionFrequency: {
      label: 'Distribution Frequency',
      value: shareClass.fund?.get_frequency_display || 'N/A',
    },
    currentDistribution: {
      label: 'Current Distribution',
      value: `$${shareClassData.current_distribution_amount?.data_value || 0}`,
    },
    trailingYield: {
      label: '12 Mo Trailing Yield',
      value: `${fundProfileData?.trailing_yield || 0}%`,
    },
    exDate: {
      label: 'Ex-Date',
      value:
        formatDateToUSA(
          shareClassData.current_distribution_ex_date?.data_value
        ) || 'N/A',
    },
    payableDate: {
      label: 'Payable Date',
      value:
        formatDateToUSA(
          shareClassData.current_distribution_payable_date?.data_value
        ) || 'N/A',
    },
    dividendGrowth3Yr: {
      label: 'Dividend Growth (3 Yr)',
      value: `${shareClassData.div_growth_3yr?.data_value || 0}%`,
    },
    dividendChange: {
      label: 'Dividend Change',
      value:
        `${shareClassData.last_div_change?.data_value || 0}% on ${formatDateToUSA(
          shareClassData.last_div_change?.date
        )}` || 'N/A',
    },
    returnOfCapital1Yr: {
      label: 'Return of Capital (1 Yr)',
      value: `${shareClassData.distribution_roc_1yr_pct?.data_value || 0}%`,
    },
    returnOfCapital3Yr: {
      label: 'Return of Capital (3 Yr)',
      value: `${shareClassData.distribution_roc_3yr_pct?.data_value || 0}%`,
    },
  };
});
</script>
