<template>
  <footer id="ft">
    <div class="disclosures">
      <div class="container">
        <h3><b>DISCLOSURES:</b></h3>
        <p>
          CEFData.com makes data for the universe of closed-end funds (CEFs) and
          business development companies (BDCs) available to its users. This
          material is not intended to be relied upon as investment advice or
          recommendations, does not constitute a solicitation to buy or sell
          securities and should not be considered specific legal, investment or
          tax advice. This site does not list all the risks associated with each
          fund. Investors should contact a fund’s sponsor for fund-specific risk
          information and/or contact a financial advisor before investing.
        </p>
        <p>
          <b>NOTES:</b> Distribution type sourced from CEFdata.com. For specific
          information about a fund's distribution sources, visit the fund
          sponsor's website.
        </p>
        <p>
          The following information applies to closed-end funds and business
          development companies in general: Fund shares are not guaranteed or
          endorsed by any bank or other insured depository institution, and are
          not federally insured by the Federal Deposit Insurance Corporation
          (FDIC). Shares of closed-end funds (CEFs) and business development
          companies (BDCs) are subject to investment risks, including the
          possible loss of principal invested. There can be no assurance that
          fund objectives will be achieved. Closed-end funds and business
          development companies frequently trade at a discount to their net
          asset value. NAV returns are net of fund expenses, and assume
          reinvestment of distributions.
        </p>
        <p>
          This material is presented for informational purposes only. Under no
          circumstances is it to be considered an offer to sell, or a
          solicitation to buy any investment referred to on this page. While the
          information contained herein is from sources believed reliable, we do
          not represent that it is accurate or complete and it should not be
          relied upon as such. Past performance is not necessarily indicative of
          future results. All investments involve risk including the loss of
          principal. Returns are presented gross of investment management fees
          and other appropriate fees (i.e. commissions, custodial fees, etc.).
        </p>
        <p>
          &copy; CEF Advisors Inc. and CEFdata.com 2025. All rights reserved.
          Any copying, republication or redistribution of CEFadvisors,
          CEFdata.com, content, including by caching, framing or similar means,
          is expressly prohibited without the prior written consent of
          CEFAdvisors. CEFAdvisors shall not be liable for any errors or delays
          in the content, or for any actions taken in reliance thereon.
        </p>
      </div>
    </div>
    <!-- /.disclosures -->
    <div class="container">
      <div class="row mb-5">
        <div class="col-xs-12 col-lg-6">
          <h3>
            Find Out How CEF Advisors Can Help You Get The Most Out Of CEF
            Investing.
          </h3>
        </div>
        <!-- /.col -->
        <div class="col-xs-12 col-lg-6">
          <form>
            <div>
              <label class="visually-hidden" for="ft-form-name">Name</label>
              <input
                type="text"
                id="ft-form-name"
                class="form-control"
                name="name"
                value=""
                placeholder="Name"
              />
            </div>
            <div>
              <label class="visually-hidden" for="ft-form-email">Email</label>
              <input
                type="email"
                id="ft-form-email"
                class="form-control"
                name="email"
                value=""
                placeholder="Email"
              />
            </div>
            <div></div>
            <div>
              <button type="submit" class="btn btn-info">
                Schedule A 15 Minute Call
              </button>
            </div>
          </form>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
      <div class="row pt-2">
        <div class="info col-xs-12 col-lg-6">
          <div>
            <div class="logos">
              <img
                src="@/assets/img/cefa-logo-inverse.png"
                class="logo"
                alt="CEF Advisors"
              />
              <div>
                <a href="#"
                  ><img src="@/assets/img/icon-linkedin.png" alt="LinkedIn"
                /></a>
                <a href="https://aicalliance.org/"
                  ><img src="@/assets/img/icon-aica.png" alt="AICA"
                /></a>
                <a href="https://aicalliance.org/alliance-content/pod-cast/"
                  ><img src="@/assets/img/icon-navigator.png" alt="Navigator"
                /></a>
              </div>
            </div>
            <!-- /.logos -->
            <div class="contact">
              <div>
                <a href="tel:8042882482">804-288-2482</a><br />
                Toll Free <a href="tel:8003563508">800-356-3508</a>
              </div>
              <div>
                7204 Glen Forest Drive<br />
                Richmond VA 23219
              </div>
            </div>
            <!-- /.contact -->
          </div>
        </div>
        <!-- /.col -->
        <div class="links col-xs-12 col-lg-6">
          <ul>
            <li>
              <h5>ABOUT</h5>
              <div><a href="#">WHY US</a></div>
              <div><a href="#">TEAM</a></div>
              <div><a href="#">PRESS</a></div>
            </li>
            <li>
              <h5>INVESTING</h5>
              <div><a href="#">FUND MODELS</a></div>
              <div><a href="#">INVESTMENT MANAGEMENT</a></div>
              <div><a href="#">UIT</a></div>
              <div><a href="#">CEF PR ARCHIVE</a></div>
            </li>
            <li>
              <h5>DATA</h5>
              <div><a href="#">DATA SERVICE</a></div>
              <div><a href="#">INDEXES</a></div>
              <div><a href="#">CEFs</a></div>
              <div><a href="#">BDCs</a></div>
            </li>
            <li class="highlight">
              <h5><a href="#">RESOURCES</a></h5>
              <div><a href="#">LOGIN</a></div>
              <div><a href="#">CONTACT</a></div>
              <div><a href="#">LEGAL</a></div>
              <div><a href="#">FORM ADV 2</a></div>
              <div><a href="#">FORM CRS</a></div>
            </li>
            <!-- /.highlight -->
          </ul>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container -->
  </footer>
  <!-- /#ft -->
</template>

<script setup></script>
