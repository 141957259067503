<template>
  <div>
    <LoadingOverlay :loading="loading">
      <LineChart :tabs="tabs" yAxisFormat="percent" :key="chartKey" />
    </LoadingOverlay>
  </div>
</template>

<script setup>
import LineChart from '@/components/charts/LineChart.vue';
import { formatDateToUSA } from '@/services/utils';
import LoadingOverlay from '@/components/ui/LoadingOverlay.vue';
import { computed, ref } from 'vue';

const props = defineProps({
  discountData: {
    type: Object,
    default: () => ({}),
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const chartKey = ref(Math.random());

const tabs = computed(() => [
  {
    name: '1 Mo',
    title: 'PREMIUM/DISCOUNT: 1 Mo',
    labels: props.discountData.mo1?.date?.map((v) => formatDateToUSA(v)) || [],
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Discount',
        data: props.discountData.mo1?.disc?.map((x) => x) || [],
      },
      {
        label: 'Peer Discount',
        data: props.discountData.mo1?.disc_peer?.map((x) => x) || [],
      },
    ],
  },
  {
    name: '3 Mo',
    title: 'PREMIUM/DISCOUNT: 3 Mo',
    labels: props.discountData.mo3?.date?.map((v) => formatDateToUSA(v)) || [],
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Discount',
        data: props.discountData.mo3?.disc?.map((x) => x) || [],
      },
      {
        label: 'Peer Discount',
        data: props.discountData.mo3?.disc_peer?.map((x) => x) || [],
      },
    ],
  },
  {
    name: 'YTD',
    title: 'PREMIUM/DISCOUNT: YTD',
    labels: props.discountData.ytd?.date?.map((v) => formatDateToUSA(v)) || [],
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Discount',
        data: props.discountData.ytd?.disc?.map((x) => x) || [],
      },
      {
        label: 'Peer Discount',
        data: props.discountData.ytd?.disc_peer?.map((x) => x) || [],
      },
    ],
  },
  {
    name: '1 Yr',
    title: 'PREMIUM/DISCOUNT: 1 YR',
    labels: props.discountData.yr1?.date?.map((v) => formatDateToUSA(v)) || [],
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Discount',
        data: props.discountData.yr1?.disc?.map((x) => x) || [],
      },
      {
        label: 'Peer Discount',
        data: props.discountData.yr1?.disc_peer?.map((x) => x) || [],
      },
    ],
  },
  {
    name: '3 Yr',
    title: 'PREMIUM/DISCOUNT: 3 YR',
    labels: props.discountData.yr3?.date?.map((v) => formatDateToUSA(v)) || [],
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Discount',
        data: props.discountData.yr3?.disc?.map((x) => x) || [],
      },
      {
        label: 'Peer Discount',
        data: props.discountData.yr3?.disc_peer?.map((x) => x) || [],
      },
    ],
  },
  {
    name: '5 Yr',
    title: 'PREMIUM/DISCOUNT: 5 YR',
    labels: props.discountData.yr5?.date?.map((v) => formatDateToUSA(v)) || [],
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Discount',
        data: props.discountData.yr5?.disc?.map((x) => x) || [],
      },
      {
        label: 'Peer Discount',
        data: props.discountData.yr5?.disc_peer?.map((x) => x) || [],
      },
    ],
  },
  {
    name: '10 Yr',
    title: 'PREMIUM/DISCOUNT: 10 YR',
    labels: props.discountData.yr10?.date?.map((v) => formatDateToUSA(v)) || [],
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Discount',
        data: props.discountData.yr10?.disc?.map((x) => x) || [],
      },
      {
        label: 'Peer Discount',
        data: props.discountData.yr10?.disc_peer?.map((x) => x) || [],
      },
    ],
  },
  {
    name: '15 Yr',
    title: 'PREMIUM/DISCOUNT: 15 YR',
    labels: props.discountData.yr15?.date?.map((v) => formatDateToUSA(v)) || [],
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Discount',
        data: props.discountData.yr15?.disc?.map((x) => x) || [],
      },
      {
        label: 'Peer Discount',
        data: props.discountData.yr15?.disc_peer?.map((x) => x) || [],
      },
    ],
  },
]);
</script>
