import axios from '@/libs/axios';
import { ref } from 'vue';
import { handleApiError } from '@/services/error';

export const useChoices = () => {
  const tickersCef = ref({});

  // Individual loading states for each API call
  const loadingTickersCef = ref(false);

  const fetchTickersCef = async (paylod) => {
    loadingTickersCef.value = true;
    try {
      const { data } = await axios.get(`choices/fund-profile-search-ticker`, {
        params: paylod,
      });
      tickersCef.value = data;
    } catch (error) {
      handleApiError(error, 'Error in search ticker ');
    } finally {
      loadingTickersCef.value = false;
    }
  };

  return {
    fetchTickersCef,
    tickersCef,
    loadingTickersCef,
  };
};
