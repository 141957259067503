<template>
  <LoadingOverlay :loading="loading">
    <div>
      <LineChart
        :tabs="tabs"
        yAxisFormat="percent"
        type="bar"
        :key="chartKey"
      />
    </div>
  </LoadingOverlay>
</template>

<script setup>
import LineChart from '@/components/charts/LineChart.vue';
import { formatNumber } from '@/services/utils';
import LoadingOverlay from '@/components/ui/LoadingOverlay.vue';
import { computed, ref } from 'vue';

const props = defineProps({
  reinvestedPriceData: {
    type: Object,
    default: () => ({}),
  },
  ticker: {
    type: String,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const periods = Array.from({ length: 5 }, (_, i) =>
  (new Date().getFullYear() - i - 1).toString()
);
const backgroundColor = ['#21739C', '#4DBFDE', '#57E5AC', '#C766FF', '#f955af'];
const borderColor = ['#21739C', '#4DBFDE', '#57E5AC', '#C766FF', '#f955af'];
const chartKey = ref(Math.random());

const tabs = computed(() => [
  {
    name: 'NAV',
    title: 'Calendar Total Return',
    subTitle: 'With Reinvestment',
    labels: periods,
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Calendar Total Return – With Reinvestment',
        data: periods.map((period) =>
          formatNumber(
            props.reinvestedPriceData?.[`nav_tr_reinvested_${period}`]?.[
              props.ticker
            ],
            2
          )
        ),
        backgroundColor: backgroundColor,
        borderColor: borderColor,
      },
    ],
  },
  {
    name: 'Price',
    title: 'Calendar Total Return',
    subTitle: 'With Reinvestment',
    labels: periods,
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Total Return – With Reinvestment',
        data: periods.map((period) =>
          formatNumber(
            props.reinvestedPriceData?.[`price_tr_reinvested_${period}`]?.[
              props.ticker
            ],
            2
          )
        ),
        backgroundColor: backgroundColor,
        borderColor: borderColor,
      },
    ],
  },
]);
</script>
