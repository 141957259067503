<template>
  <LoadingOverlay :loading="loading">
    <!-- Tax Dividend Data -->
    <div class="table-responsive">
      <table class="table sidebar">
        <thead>
          <tr>
            <th colspan="3">Tax Dividend Data</th>
          </tr>
        </thead>
        <tbody>
          <tr class="subhead">
            <th></th>
            <th>{{ header.current }}</th>
            <th>{{ header.past }}</th>
          </tr>
          <tr
            v-for="(item, index) in distributionBreakdownData"
            :key="`item_${index}`"
          >
            <th>
              {{ item.label }}
            </th>
            <td>{{ item.current }}</td>
            <td>{{ item.past }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- /.table-responsive -->
  </LoadingOverlay>
</template>

<script setup>
import { computed } from 'vue';
import { formatNumber } from '@/services/utils';
import LoadingOverlay from '@/components/ui/LoadingOverlay.vue';

const props = defineProps({
  taxDividendData: {
    type: Object,
    default: () => ({}),
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const header = computed(() => {
  const { taxDividendData } = props;
  return {
    current: taxDividendData?.latest_tax_year,
    past: `${taxDividendData?.first_tax_year} - ${taxDividendData?.latest_tax_year}`,
  };
});

const distributionBreakdownData = computed(() => {
  const { taxDividendData } = props;

  return {
    income: {
      label: 'Income',
      current: `${taxDividendData?.tax_income_1yr || 0}%`,
      past: `${taxDividendData?.tax_income_3yr || 0}%`,
    },
    stGains: {
      label: 'ST Gains',
      current: `${taxDividendData?.tax_s_gain_1yr || 0}%`,
      past: `${taxDividendData?.tax_s_gain_3yr || 0}%`,
    },
    qdi: {
      label: 'QDI',
      current: `${formatNumber(taxDividendData?.tax_qdi_1yr) || 0}%`,
      past: `${formatNumber(taxDividendData?.tax_qdi_3yr) || 0}%`,
    },
    ltGains: {
      label: 'LT Gains',
      current: `${taxDividendData?.tax_l_gain_1yr || 0}%`,
      past: `${taxDividendData?.tax_l_gain_3yr || 0}%`,
    },
    roc: {
      label: 'RoC',
      current: `${taxDividendData?.tax_roc_1yr || 0}%`,
      past: `${taxDividendData?.tax_roc_3yr || 0}%`,
    },
  };
});
</script>
