<template>
  <div class="table-responsive">
    <table class="table sidebar">
      <thead>
        <tr>
          <th colspan="2">Capital & Leverage</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, key) in tableData" :key="key">
          <th>
            {{ item.label }}
            <template v-if="item.subLabel">
              <small>({{ item.subLabel }})</small>
            </template>
          </th>
          <td>{{ item.value }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { formatDateToString, formatNumber } from '@/services/utils';

const props = defineProps({
  shareClass: {
    type: Object,
    default: () => ({}), // Default value as an empty object
  },
  shareClassData: {
    type: Object,
    default: () => ({}), // Default value as an empty object
  },
});

const tableData = computed(() => {
  const { shareClass, shareClassData } = props;

  return {
    outstandingShares: {
      label: 'Outstanding Shares',
      value: formatNumber(shareClassData.shares_value?.data_value) || '-',
    },
    totalAssets: {
      label: 'Total Assets',
      subLabel: `Reported: ${formatDateToString(shareClassData.total_assets?.date)}`,
      value: `$${formatNumber(shareClassData.total_assets?.data_value) || '0'}M`,
    },
    estimatedTotalAssets: {
      label: 'Estimated Total Assets',
      value: `$${formatNumber(shareClassData.total_assets_estimated?.data_value) || '0'}M`,
    },
    netAssets: {
      label: 'Net Assets',
      value: `$${formatNumber(shareClassData.net_assets_current?.data_value) || '0'}M`,
    },
    marketCap: {
      label: 'Market Cap',
      value: `$${formatNumber(shareClassData.market_cap?.data_value) || '0'}M`,
    },
    portfolioTurnover: {
      label: 'Portfolio Turnover',
      value: `${shareClassData?.turnover_rate?.data_value || '0'}%`,
    },
    ...(shareClass.is_fund_type_dbc
      ? {
          debtToEquity: {
            value: `${shareClassData.debt_to_equity?.data_value || '0'}`,
            label: 'Debt To Equity',
          },
        }
      : {}),

    totalLeverage: {
      label: 'Total Leverage',
      value: `${shareClassData.total_leverage_pct?.data_value || '0'}%`,
    },
    structuralLeverage: {
      label: 'Structural Leverage (out of total)',
      value: `${shareClassData.struct_lev_from_total?.data_value || '0'}%`,
    },
    portfolioLeverage: {
      label: 'Portfolio Leverage (out of total)',
      value: `${shareClassData.port_lev_from_total?.data_value || '0'}%`,
    },
    expenseRatio: {
      label: 'Expense Ratio',
      value: `${formatNumber(shareClassData.expense_ratio_pct?.data_value, 2)}%`,
    },
    nonLeveragedExpenseRatio: {
      label: 'Non Leveraged Expense Ratio',
      value: `${formatNumber(shareClassData.non_lev_exp_ratio?.data_value, 2)}%`,
    },

    ...(shareClass.is_fund_type_dbc
      ? {
          grossExpenseRatio: shareClass.fund.is_fund_type_dbc && {
            value: `${shareClassData.gross_expense_ratio_pct?.data_value || 'N/A'}%`,
            label: 'Gross Asset Expense Ratio',
          },
        }
      : {}),

    ...(shareClass.is_fund_type_dbc
      ? {
          grossNonLeveragedExpenseRatio: shareClass.fund.is_fund_type_dbc && {
            value: `${shareClassData.non_lev_gross_expense_ratio_pct?.data_value || 'N/A'}%`,
            label: 'Gross Asset Non Lev Exp Ratio',
          },
        }
      : {}),

    relativeLeverageCost: {
      label: 'Relative Leverage Cost',
      value: `${shareClassData.rel_lev_cost?.data_value || '0'}%`,
    },
  };
});
</script>
