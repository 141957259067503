import { parse, getQuarter, format } from 'date-fns';

/**
 * Utility function to format a date as "Sep 6, 2024"
 * @param {Date} date - The date to be formatted
 * @returns {string} - The formatted date as "Sep 6, 2024"
 */
export const formatDateToString = (date) => {
  // Formats the given date into a string like "Sep 6, 2024"
  return date ? format(date, 'MMM. d, yyyy') : '-';
};

/**
 * Utility function to format a date in the USA format "MM/dd/yyyy"
 * @param {Date} date - The date to be formatted
 * @returns {string} - The formatted date as "MM/dd/yyyy"
 */
export const formatDateToUSA = (date) => {
  // Formats the given date into USA date format (MM/dd/yyyy)
  return date ? format(date, 'MM/dd/yyyy') : '-';
};

/**
 * Formats a price change as a string, prepending a negative sign for losses
 * @param {number} priceChange - The price change value
 * @returns {string} - The formatted price change, e.g. "$25.00" or "-$25.00"
 */
export const formatValueChange = (priceChange) => {
  // Format the absolute value of priceChange to 2 decimal places
  const formattedValue = Math.abs(priceChange).toFixed(2);

  // Return the formatted value with a negative sign if the price change is negative
  return priceChange < 0 ? `-$${formattedValue}` : `$${formattedValue}`;
};

/**
 * Formats a number with a specified number of decimal places
 * @param {number} value - The number to be formatted
 * @param {number} digits - The number of decimal places to display
 * @param {object} format - Additional options for number formatting
 * @returns {string} - The formatted number
 */
export const formatNumber = (value, digits = 0, fallback = 0, format = {}) => {
  // Use fallback if value is null or undefined
  const num = value ?? fallback;

  return new Intl.NumberFormat([], {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
    ...format,
  }).format(num);
};

export const getDomain = (url) => {
  try {
    const urlObject = new URL(url);
    return urlObject.hostname; // this will give you "www.triplepointcapital.com"
  } catch (e) {
    // eslint-disable-next-line
      console.error('Invalid URL', e)
    return null;
  }
};

export const dateToMonthName = (date) => {
  return format(new Date(date), 'MMMM');
};

export const dateToQuarter = (dateString, includeYear = true) => {
  const date = parse(dateString, 'yyyy-MM-dd', new Date()); // Parse the date in 'YYYY-MM-DD' format
  const quarter = `Q${getQuarter(date)}`; // Get the quarter (e.g., Q1, Q2, etc.)

  if (includeYear) {
    const year = format(date, 'yyyy'); // Extract the year
    return `${quarter} ${year}`; // Combine quarter and year
  }

  return quarter; // Return only the quarter if year is not included
};
