<template>
  <div id="content" class="home-page py-0">
    <div class="row g-0">
      <div class="col-md-6">
        <section class="component dark py-0 is-visible">
          <div class="card hero-card border-0 rounded-0 text-white">
            <img
              class="card-img rounded-0"
              src="../../assets/img/home-hero-1.png"
              alt="Card image"
            />
            <div class="card-img-overlay">
              <div
                class="d-flex flex-column justify-content-end align-items-center text-center w-100 h-50"
              >
                <h2 class="mb-0">PERFORMANCE DRIVEN.</h2>
              </div>

              <div
                class="d-flex flex-column justify-content-center align-items-center text-center w-100 h-50"
              >
                <a href="#" class="btn btn-info-2 btn-lg">EXPLORE INVESTING</a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- /.col -->
      <div class="col-md-6">
        <section class="component dark py-0 is-visible">
          <div class="card hero-card border-0 rounded-0 text-white">
            <img
              class="card-img rounded-0"
              src="../../assets/img/home-hero-2.png"
              alt="Card image"
            />
            <div class="card-img-overlay">
              <div
                class="d-flex flex-column justify-content-end align-items-center text-center w-100 h-50"
              >
                <h2 class="mb-0">DATA OBSESSED.</h2>
              </div>

              <div
                class="d-flex flex-column justify-content-center align-items-center text-center w-100 h-50"
              >
                <a href="#" class="btn btn-default btn-lg">EXPLORE RESEARCH</a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->

    <div class="container py-5">
      <div class="row py-5">
        <div class="col-lg-6">
          <section class="cef-investing">
            <h2 class="mb-3 bold">
              NATIONALLY RECOGNIZED SPECIALISTS IN CEF INVESTING
            </h2>
            <p>
              Need exceptional expertise to maximize your outcomes? Look no
              further than CEF Advisors, a widely respected authority on
              closed-end funds. Few registered investment advisors can rival our
              CEF, BDC and interval fund experience, our resources or our deep
              data-driven understanding of the market. And we’re confident none
              are as dedicated. Our passion is simply unmatched.
            </p>

            <p>
              Our data obsession and unyielding drive to deliver advanced
              investment management can transform your results. For more than 35
              years, we’ve lived at the pivotal crossroads of CEF investing,
              research and advocacy. Our portfolio managers are committed to
              making visionary investment decisions based on fresh, accurate
              information hand-compiled by our data experts. We deliver the
              vital data and insights you need when you need them most — now.
            </p>
          </section>
        </div>
        <div class="col-lg-6">
          <LoadingOverlay :loading="loadingAicastats">
            <div class="investment-chart">
              <div class="chart-container">
                <DonutChart :items="bkChartData">
                  <template v-slot:overlay-content="{ item, index }">
                    <div>
                      <h1>{{ item.fund }}</h1>
                      <p>{{ item.fund_label }}</p>
                      <h5 style="height: 10px"></h5>
                      <h1><small>$</small>{{ item.asset }}</h1>
                      <p>{{ item.asset_label }}</p>
                    </div>
                  </template>
                </DonutChart>
              </div>
            </div>
          </LoadingOverlay>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container -->

    <swiper
      :slidesPerView="1.5"
      :spaceBetween="10"
      :loop="true"
      :centeredSlides="true"
      :navigation="true"
      :autoplay="{
        delay: 3000,
        disableOnInteraction: false,
      }"
      :breakpoints="{
        0: {
          slidesPerView: 1.2,
          spaceBetween: 10,
        },
        750: {
          slidesPerView: 2.2,
          spaceBetween: 20,
        },
        1000: {
          slidesPerView: 3.2,
          spaceBetween: 40,
        },
        1500: {
          slidesPerView: 4.2,
          spaceBetween: 40,
        },
      }"
      :modules="[Navigation, Pagination, Autoplay]"
      class="swiper companies-slider py-0"
    >
      <swiper-slide>
        <img src="../../assets/img/fund-fire.png" class="mb-2" />
        <a href="#" class="text-center"
          >Harum sunt. Temporeicae laut et, conesti del incium vernatur sim
          fugitatio. Et exerfer enimust ruptas</a
        >
      </swiper-slide>
      <swiper-slide
        ><img src="../../assets/img/wealthmanagement.png" class="mb-2" />
        <a href="#" class="text-center"
          >Harum sunt. Temporeicae laut et, conesti del incium vernatur sim
          fugitatio. Et exerfer enimust ruptas</a
        ></swiper-slide
      ><swiper-slide
        ><img src="../../assets/img/navigator.png" class="mb-2" />
        <a href="#" class="text-center"
          >Harum sunt. Temporeicae laut et, conesti del incium vernatur sim
          fugitatio. Et exerfer enimust ruptas</a
        ></swiper-slide
      >
      <swiper-slide>
        <img src="../../assets/img/fund-fire.png" class="mb-2" />
        <a href="#" class="text-center"
          >Harum sunt. Temporeicae laut et, conesti del incium vernatur sim
          fugitatio. Et exerfer enimust ruptas</a
        >
      </swiper-slide>
      <swiper-slide
        ><img src="../../assets/img/wealthmanagement.png" class="mb-2" />
        <a href="#" class="text-center"
          >Harum sunt. Temporeicae laut et, conesti del incium vernatur sim
          fugitatio. Et exerfer enimust ruptas</a
        ></swiper-slide
      ><swiper-slide
        ><img src="../../assets/img/navigator.png" class="mb-2" />
        <a href="#" class="text-center"
          >Harum sunt. Temporeicae laut et, conesti del incium vernatur sim
          fugitatio. Et exerfer enimust ruptas</a
        ></swiper-slide
      >
    </swiper>

    <section class="component is-visible">
      <div class="card-insight border-0 rounded-0 text-white position-relative">
        <div class="card-insight-in">
          <div class="container">
            <!-- First Row (Two Sections) -->
            <div class="row row-1">
              <div class="col-lg-6">
                <div class="col-in bg-white w-100">
                  <h2 class="text-uppercase text-blue">
                    Infinite Possibilities. Customized for You.
                  </h2>
                  <img
                    src="../../assets/img/homepage-icons_portfolios.png"
                    class="img-fluid w-100"
                  />
                  <a href="#" class="btn btn-default btn-lg mt-2"
                    >Explore Portfolios</a
                  >
                </div>
              </div>
              <div class="col-lg-6">
                <div class="col-in bg-white w-100">
                  <h2 class="text-uppercase text-blue">
                    See How Your Investments Stack Up.
                  </h2>
                  <img
                    src="../../assets/img/homepage-icons_index performance.png"
                    class="img-fluid w-100"
                  />
                  <a href="#" class="btn btn-default btn-lg mt-2"
                    >Compare Index Performance</a
                  >
                </div>
              </div>
            </div>

            <!-- Second Row (Insights Section) -->
            <div class="row row-2">
              <!-- Left Side (Text & Button) -->
              <div class="col-lg-8">
                <div class="col-in bg-white w-100">
                  <h2 class="text-uppercase text-blue">
                    Get Fresh Insights with Our Q2 CEF, BDC & Interval Fund
                    Research Review July 19, 2PM EST
                  </h2>
                  <div class="mt-3">
                    <a href="#" class="btn btn-default btn-lg">Register</a>
                  </div>
                </div>
              </div>

              <!-- Right Side (Image) -->
              <div class="col-lg-4">
                <div class="col-in bg-white p-3 w-100 h-100">
                  <img
                    src="../../assets/img/homepage-icons_insights.png"
                    class="img-fluid w-100"
                    style="object-fit: contain; max-height: 245px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="component is-visible bg-white">
      <div class="container">
        <div class="download-box">
          <h6 class="text-center">CEF ADVISORS EXCLUSIVE:</h6>

          <div class="form-div">
            <div class="form-div-in">
              <h2 class="text-white">DIG INTO OUR DATA:</h2>
              <h3 class="text-gray-800">
                10 years of asset class annual returns and average
                discounts/premiums from 2014 to 2024
              </h3>

              <form>
                <div>
                  <label class="visually-hidden" for="ft-form-name">Name</label>
                  <input
                    type="text"
                    id="ft-form-name"
                    class="form-control"
                    name="name"
                    value=""
                    placeholder="Name"
                    style="margin-bottom: 10px"
                  />
                </div>
                <div>
                  <label class="visually-hidden" for="ft-form-email"
                    >Email</label
                  >
                  <input
                    type="email"
                    id="ft-form-email"
                    class="form-control"
                    name="email"
                    value=""
                    placeholder="Email"
                    style="margin-bottom: 10px"
                  />
                </div>
                <div>
                  <button type="submit" class="btn btn-default">
                    DOWNLOAD
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div class="chart-div">
            <img
              src="../../assets/img/homepage-chart-image.png"
              class="w-100"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="component is-visible bg-white">
      <swiper
        :slidesPerView="1.5"
        :spaceBetween="10"
        :loop="true"
        :centeredSlides="true"
        :navigation="true"
        :autoplay="{
          delay: 3000,
          disableOnInteraction: false,
        }"
        :breakpoints="{
          0: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
          750: {
            slidesPerView: 2.5,
            spaceBetween: 30,
          },
          1000: {
            slidesPerView: 2.9,
            spaceBetween: 30,
          },
          1500: {
            slidesPerView: 2.9,
            spaceBetween: 30,
          },
        }"
        :modules="[Navigation, Pagination, Autoplay]"
        class="swiper investor-slider"
      >
        <swiper-slide>
          <p>
            I like the quick and easy access to seeing what the hedge funds are
            buying.
          </p>
          <h6 class="text-center">Kyle Gearhart, Gearhart & Associates</h6>
        </swiper-slide>
        <swiper-slide>
          <p>
            CEF Advisors’ focused approach provides the most extensive spectrum
            of CEF and BDC data on the market.
          </p>
          <h6 class="text-center">
            Daniel Lushan, FIRM NAME MISSING
          </h6></swiper-slide
        ><swiper-slide
          ><p>
            As an active investor in closed-end funds, keeping up with the
            latest news is important. The only way to reliably do that is
            through the News Data Service at CEF Advisors
          </p>
          <h6 class="text-center">
            Richard Abraham, FIRM NAME MISSING
          </h6></swiper-slide
        >
        <swiper-slide>
          <p>
            I vigorously endorse CEF Advisors’ data service. As a long-time
            closed-end fund investor, I don’t know how many times I’ve said to
            myself that one daily tidbit was worth the annual subscription.
          </p>
          <h6 class="text-center">Sander Ross, Melinhill Capital</h6>
        </swiper-slide>
      </swiper>
    </section>
  </div>
</template>
<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import DonutChart from '@/components/charts/DonutChart.vue';
import { useHomePage } from './composable/useHomePage';
import { onBeforeMount, computed } from 'vue';
import LoadingOverlay from '@/components/ui/LoadingOverlay.vue';

const { aicastats, loadingAicastats, fetchAicastats } = useHomePage();

onBeforeMount(async () => {
  await fetchAicastats();
});

const bkChartData = computed(() => {
  // Sum all fund values
  const totalFunds =
    (aicastats.value.traditional_cefs || 0) +
    (aicastats.value.tender_offer_funds || 0) +
    (aicastats.value.interval_funds || 0) +
    (aicastats.value.non_listed_bdcs || 0) +
    (aicastats.value.listed_bdcs || 0);

  // Prevent division by zero (default to 1)
  const divisor = totalFunds || 1;

  return [
    {
      fund: aicastats.value.all_funds,
      fund_label: 'Funds',
      asset: aicastats.value.all_assets,
      asset_label: 'Billion in Assets',
      value: 0.001,
    },
    {
      fund: aicastats.value.traditional_cefs,
      fund_label: 'Traditional Listed Closed-End Funds',
      asset: aicastats.value.traditional_cefs_assets,
      asset_label: 'Billion',
      value: Math.round((aicastats.value.traditional_cefs / divisor) * 100),
    },
    {
      fund: aicastats.value.tender_offer_funds,
      fund_label: 'Tender Offer Funds (non BDC)',
      asset: aicastats.value.tender_offer_funds_assets,
      asset_label: 'Billion',
      value: Math.round((aicastats.value.tender_offer_funds / divisor) * 100),
    },
    {
      fund: aicastats.value.interval_funds,
      fund_label: 'Interval Funds',
      asset: aicastats.value.interval_funds_assets,
      asset_label: 'Billion',
      value: Math.round((aicastats.value.interval_funds / divisor) * 100),
    },
    {
      fund: aicastats.value.non_listed_bdcs,
      fund_label: 'BDCs Non Listed',
      asset: aicastats.value.non_listed_bdcs_assets,
      asset_label: 'Billion',
      value: Math.round((aicastats.value.non_listed_bdcs / divisor) * 100),
    },
    {
      fund: aicastats.value.listed_bdcs,
      fund_label: 'Listed BDCs',
      asset: aicastats.value.listed_bdcs_assets,
      asset_label: 'Billion',
      value: Math.round((aicastats.value.listed_bdcs / divisor) * 100),
    },
  ];
});
</script>
