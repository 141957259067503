<template>
  <nav id="navigation" class="fixed-top">
    <div id="navbar" class="navbar navbar-expand-lg">
      <div class="container" ref="navigationContainerRef">
        <a href="/" class="navbar-brand">
          <span class="visually-hidden">CEF Advisors</span>
          <img
            :src="require('@/assets/img/cefa-logo.png')"
            alt="CEF Advisors"
            class="logo"
          />
        </a>
        <button
          class="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#main-navigation"
          aria-controls="main-navigation"
          :aria-expanded="showNavigation.toString()"
          aria-label="Toggle navigation"
          @click="toggleMobileNavigation"
        >
          <span class="fass fal fa-bars" aria-hidden="false"></span>
          <span class="fass fal fa-xmark" aria-hidden="true"></span></button
        ><!-- /.navbar-toggler -->
        <div
          id="main-navigation"
          class="collapse navbar-collapse"
          :class="{ show: showNavigation }"
        >
          <form
            id="hdr-ticker-form"
            method="GET"
            class="form-inline"
            action="#"
            role="form"
          >
            <div class="input-group input-group-sm">
              <input
                type="text"
                placeholder="CEF OR BDC FUND TICKER"
                name="ticker"
                class="form-control"
                aria-label="Enter Fund Symbol"
                aria-describedby="ticker-label"
                v-model="searchQuery"
              />
              <ul
                class="dropdown-menu dropdown-menu-ticker-list"
                :class="{
                  show:
                    showSearchBoxOptions &&
                    (loadingTickersCef || tickersCef.length),
                }"
              >
                <li class="loading-spinner" v-if="loadingTickersCef">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </li>
                <li
                  v-for="res in tickersCef"
                  :key="res.id"
                  class="dropdown-item"
                  @click="selectTicker(res.ticker)"
                >
                  <h4 class="p-0 m-0">{{ res.ticker }}</h4>
                  <p>{{ res.fund_name }}</p>
                </li>
              </ul>
              <button type="button" id="hdr-ticker-form-btn">
                <i class="far fa-magnifying-glass"></i
                ><span class="visually-hidden" id="search-label">Search</span>
              </button>
            </div>
            <!-- /.input-group -->
          </form>
          <!-- /#hdr-ticker-form -->
          <ul id="main-nav" class="navbar-nav ml-lg-auto">
            <li
              itemscope="itemscope"
              itemtype="https://www.schema.org/SiteNavigationElement"
              class="nav-item"
            >
              <a href="#" class="nav-link">About</a>
            </li>
            <li
              itemscope="itemscope"
              itemtype="https://www.schema.org/SiteNavigationElement"
              class="nav-item"
            >
              <a href="#" class="nav-link">Investing</a>
            </li>
            <li
              itemscope="itemscope"
              itemtype="https://www.schema.org/SiteNavigationElement"
              class="nav-item"
            >
              <a href="#" class="nav-link">Data</a>
            </li>
            <li
              itemscope="itemscope"
              itemtype="https://www.schema.org/SiteNavigationElement"
              class="nav-item"
            >
              <a href="#" class="nav-link">Resources</a>
            </li>
            <li
              itemscope="itemscope"
              itemtype="https://www.schema.org/SiteNavigationElement"
              class="nav-item"
            >
              <a href="#" class="nav-link">Contact</a>
            </li>
            <li
              itemscope="itemscope"
              itemtype="https://www.schema.org/SiteNavigationElement"
              class="nav-item"
            >
              <a href="#" class="btn btn-default btn-sm">Login</a>
            </li>
          </ul>
        </div>
        <!-- .#main-navigation -->
      </div>
      <!-- /.container -->
    </div>
    <!-- /#navbar -->
  </nav>
  <!-- /#navigation -->
</template>

<script setup>
import { ref, onUnmounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useChoices } from '../../composable/useChoices';

const showNavigation = ref(false);
const navigationContainerRef = ref(null);
const router = useRouter();

const showSearchBoxOptions = ref(false);
const searchQuery = ref('');

const { fetchTickersCef, tickersCef, loadingTickersCef } = useChoices();

const doTickerSearch = () => {
  if (searchQuery.value.trim().length >= 2) {
    showSearchBoxOptions.value = true;
    fetchTickersCef({ q: searchQuery.value.trim() });
    document.addEventListener('click', handleClickOutsideDropDown);
  }
};

watch(searchQuery, doTickerSearch);

const selectTicker = async (ticker) => {
  showNavigation.value = !showNavigation.value;
  showSearchBoxOptions.value = false;
  searchQuery.value = '';
  document.removeEventListener('click', handleClickOutsideDropDown);
  router.push({ name: 'FundProfile', params: { ticker: ticker } });
};

const toggleMobileNavigation = () => {
  showNavigation.value = !showNavigation.value;

  if (showNavigation.value) {
    document.addEventListener('click', handleClickOutsideMobileNavigation);
  } else {
    document.removeEventListener('click', handleClickOutsideMobileNavigation);
  }
};

const handleClickOutsideMobileNavigation = (event) => {
  if (
    navigationContainerRef.value &&
    !navigationContainerRef.value.contains(event.target)
  ) {
    showNavigation.value = false;
    document.removeEventListener('click', handleClickOutsideMobileNavigation);
  }
};

const handleClickOutsideDropDown = (event) => {
  if (
    navigationContainerRef.value &&
    !navigationContainerRef.value.contains(event.target)
  ) {
    showSearchBoxOptions.value = false;
    document.removeEventListener('click', handleClickOutsideDropDown);
  }
};

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutsideMobileNavigation);
  document.removeEventListener('click', handleClickOutsideDropDown);
});
</script>
